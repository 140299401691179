.quickStylesBar {
  margin: 24px;
  // .QuickFiltersButtons{
  Button {
    height: 26px;
    line-height: 0px;
    font-size: 12px;
    margin: 8px;
  }

  // }
  .MuiPickerStaticWrapper-root {
    // .PrivatePickersSlideTransition-root{
    .MuiTypography-caption {
      font-size: 13px;
      font-family: Roboto;
      font-weight: 900;
      font-style: normal;
    }
    Button {
      height: 10pxpx;
      line-height: 5px;
      font-size: 12px;
      margin: 0px;
      font-family: Roboto;
      font-weight: 400;
      font-style: normal;
    }
    // }
  }

  .delayed {
    background-color: #ffe3e1;
    color: #e53d2c !important;
  }
  .untracked {
    background-color: #ffaba2;
    color: #ac1000 !important;
  }
  .reset {
    background-color: #0ab38c;
    color: #ffffff !important;
  }
}
.iconButtons {
  background-color: #f1f5f7;
  border-color: #614bcf !important;
}

//status text
.actionText {
  font-weight: 700;
  font-size: 13px;
}

//action dropdown
.dropbtn {
  color: white;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: fixed;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.action-button {
  cursor: pointer;
  color: #2800fc !important;
}

.action-button-disabled {
  pointer-events: none !important;
  opacity: 0.7;
  color: #2800fc !important;
}

.action-button-cancel {
  cursor: pointer;
  color: #e53d2c !important;
}

//comment dropdown
.comment {
  position: relative;
  display: inline-block;
}

.comment-content {
  display: none;
  position: fixed;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.comment-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.comment-content div:hover {
  background-color: #ddd;
}

.comment:hover .comment-content {
  display: block;
}

// .action-button {
//   cursor: pointer;
//   color: black !important;
// }

//calender styles
.quickStylesBar {
  .MuiPickerStaticWrapper-root {
    position: fixed;
    z-index: 9999;
    direction: ltr !important;
  }
}
.consent-react_selector {
  min-width: 19%;
}
.consentBackground {
  background-color: #f1f5f7;
  padding: 10px;
  border-radius: 6px;
  .consentText {
    font-size: 15px;
    font-weight: 400;
    font-family: "Roboto";
    white-space: nowrap;
  }
  .unTrackedValueDiv {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    cursor: default;
  }
}
.QualityCheckModel {
  .modal-content {
    height: fit-content;
    padding: 20px;
    //  overflow-y: scroll;
  }
}
.QualityCheckCard {
  height: 400px;
  max-height: 420px;
  overflow-y: scroll;
  width: 95%;
  margin-left: 20px;
  background-color: #f1f5f7;
}
.QCImangeClose {
  position: absolute;
  background-color: white;
  width: 20px;
  margin-left: 28px;
  height: 20px;
  border-radius: 11px;
  margin-top: 3px;
  margin-right: 28px;
  .closeIconQc {
    position: relative;
    padding-left: 4px;
    margin-top: -2px;
  }
}
.EpodAck {
  .modal-content {
    // min-width: 900px;
    padding: 25px;
  }
}
.DamagedShortageDetails {
  .modal-content {
    padding: 25px;
  }
}
.EPODImangeClose {
  position: absolute;
  background-color: white;
  width: 20px;
  margin-left: 47px;
  height: 20px;
  border-radius: 11px;
  margin-top: 7px;
  margin-right: 28px;
  .closeIconEPOD {
    position: relative;
    padding-left: 4px;
    margin-top: -2px;
  }
}
.inputBox {
  width: 100%;
  height: 30px;
  border: 1px solid #d4c5c5;
}
@media (max-width: 640px) {
  .mobilePaddingBox {
    padding-top: 120px;
  }
  .pdt10 {
    padding-top: 10px;
  }
}

.LRCopyFont {
  color: #05284e;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  padding-top: 26px;
  padding-bottom: 26px;
  padding-left: 22px;
  padding-right: 22px;
  gap: 16px;
}

.LRCopyFontLabel {
  color: #05284e;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 18px;
  padding-left: 22px;
  margin-bottom: 12px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #b9b9b9;
}

.ConsignorLabel {
  color: #05284e;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ConsignorValue {
  color: #05284e;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ConsignorBcg {
  flex: 1;
  border-radius: 4px;
  background: #f1f5f7;
}

.ConsignorFont {
  color: #05284e;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ConsignorMainBcg {
  display: flex;
  gap: 7px;
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 26px;
}

.ConsignorFontDiv {
  display: flex;
}

.fieldsLabel {
  color: #05284e;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 6px;
}

.fieldsLabelStar {
  color: #e53d2c;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fieldsRow {
  display: flex;
  gap: 16px;
  margin-bottom: 18px;
}

.textFieldStyle {
  border-radius: 4px;
  border: 0.5px solid #aeaeae;
  background: #fff;
}

.selectField {
  background-color: "#8e9af8";
  // width: "54px";
  height: "26px";
  text-align: "center";
  justify-content: "center";
  border-radius: "0px 4px 4px 0px";
}

.LBHStyle {
  display: flex;
  gap: 12px;
  align-items: center;
}

.pdf-for {
  position: absolute;
  right: -32px;
  top: 50%;
  transform: rotate(-90deg);
  color: #373737;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.272px; /* 152.263% */
  // right: 0px;
}

.pdf-main-container {
  padding-top: 47.5px;
  padding-left: 26px;
  padding-bottom: 47.5px;
  padding-right: 26px;
  zoom: 1.5;
  // border: 1px solid #373737;
  background: #fff;
  min-width: 830px;
  width: fit-content;
  min-height: 550px;
  // transform: rotate(90deg);
  flex-shrink: 0;
  position: relative;
}

.logo-details-container {
  width: 790px;
  // border: 1px solid #373737;
  height: 96.71px;
  display: flex;
}

.logo-address-container {
  display: flex;
}

.logo {
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.272px; /* 200% */
  width: 145.285px;
  height: 75.556px;
  flex-shrink: 0;
  border-left: 0.761px solid #373737;
  border-top: 0.761px solid #373737;
  background: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
}

.logo-address {
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 700;
  line-height: 12.181px; /* 133.333% */
  width: 210.252px;
  height: 75.556px;
  flex-shrink: 0;
  border-top: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
  border-left: 0.761px solid #373737;
  background: #fff;
  align-items: center;
  text-align: left;
  padding-top: 7.64px;
  padding-bottom: 6.16px;
  padding-left: 8.46px;
  padding-right: 15.79px;
}

.booking-code-container {
  display: flex;
}

.booking {
  width: 75.576px;
  height: 21.154px;
  flex-shrink: 0;
  border-top: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
  border-left: 0.761px solid #373737;
  background: #fff;
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.272px; /* 200% */
  align-items: center;
  justify-content: center;
  display: flex;
}

.booking-value {
  width: 280.137px;
  height: 21.154px;
  flex-shrink: 0;
  border-top: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
  // border-bottom: 0.761px solid #373737;
  background: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
}

.origin-desti-container {
  // height: 47.036px;
}

.origin {
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.272px; /* 200% */
  display: flex;
}

.origin-label {
  width: 110.694px;
  height: 23.518px;
  flex-shrink: 0;
  border-top: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5.28px;
}

.origin-value {
  width: 109.694px;
  height: 23.518px;
  flex-shrink: 0;
  border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5.28px;
}

.desti {
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.272px; /* 200% */
  display: flex;
}

.desti-value {
  width: 109.694px;
  height: 17.849px;
  flex-shrink: 0;
  border-left: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  border-top: 0.761px solid #373737;
  background: #fff;
  padding-left: 5.28px;
}

.desti-label {
  width: 109.694px;
  height: 17.849px;
  flex-shrink: 0;
  border-bottom: 0.761px solid #373737;
  border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5.28px;
}

.invoice-label {
  width: 109.694px;
  height: 17.849px;
  flex-shrink: 0;
  // border-bottom: 0.761px solid #373737;
  // border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5.28px;
}

.invoice-value {
  width: 109.694px;
  height: 17.849px;
  flex-shrink: 0;
  border-left: 0.761px solid #373737;
  // border-bottom: 0.761px solid #373737;
  // border-top: 0.761px solid #373737;
  background: #fff;
  padding-left: 5.28px;
}

.blank {
  display: flex;
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.272px; /* 200% */
  // display: flex;
}

.blank1 {
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.272px; /* 200% */
  display: flex;
}

.blank1-label {
  width: 110.694px;
  height: 23.518px;
  flex-shrink: 0;
  border-top: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5.28px;
  border-left: 0.761px solid #373737;
}

.blank1-value {
  width: 109.694px;
  height: 23.518px;
  flex-shrink: 0;
  border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5.28px;
  border-right: 0.761px solid #373737;
}

.blank2-value {
  width: 109.694px;
  height: 17.849px;
  flex-shrink: 0;
  border-left: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  border-top: 0.761px solid #373737;
  background: #fff;
  padding-left: 5.28px;
  border-right: 0.761px solid #373737;
}

.blank2-label {
  width: 109.694px;
  height: 17.849px;
  flex-shrink: 0;
  border-bottom: 0.761px solid #373737;
  border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5.28px;
  border-left: 0.761px solid #373737;
}

.blank2 {
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.272px; /* 200% */
  display: flex;
}

.origin-desti-main-container {
  display: flex;
}

.blank11 {
  width: 109.694px;
  height: 17.849px;
  border-bottom: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
  border-left: 0.761px solid #373737;
}

.blank12 {
  width: 109.694px;
  height: 17.849px;
  border-bottom: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
}

.blank21 {
  width: 109.694px;
  height: 17.849px;
  border-bottom: 0.761px solid #373737;
  border-left: 0.761px solid #373737;
}

.blank22 {
  width: 109.694px;
  height: 17.849px;
  border-bottom: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
  border-left: 0.761px solid #373737;
}

.eway-pay-container {
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.272px; /* 200% */
  display: flex;
}

.eway-label {
  width: 109.694px;
  height: 21.154px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5.28px;
  border-right: 0.761px solid #373737;
  // border-bottom: 0.761px solid #373737;
}

.eway-value {
  width: 218.125px;
  height: 21.154px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  // border-bottom: 0.761px solid #373737;
  padding-left: 5.28px;
}

.pay-label {
  width: 111.694px;
  height: 21.154px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  // border-bottom: 0.761px solid #373737;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5.28px;
}

.consignor-consignee-container {
  display: flex;
  height: 164px;
  width: 794.762px;
  border: 0.761px solid #373737;
}

.consignor-container {
  width: 354.776px;
  min-height: 100px;
  flex-shrink: 0;
  // border-bottom: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
  // border-left: 0.761px solid #373737;
  background: #fff;
}

.consignee-container {
  width: 435.986px;
  // height: 95.623px;
  flex-shrink: 0;
  border-bottom: 0.761px solid #373737;
  // border-right: 0.761px solid #373737;
  // border-left: 0.761px solid #373737;
  background: #fff;
}

.consignee-container {
  // width: 435.986px;
  min-height: 100px;
  flex-shrink: 0;
  // border-bottom: 0.761px solid #373737;
  // border-right: 0.761px solid #373737;
  background: #fff;
}

.consignor {
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.272px; /* 200% */
  display: flex;
  align-items: center;
  gap: 6px;
}

.consignee {
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.272px; /* 200% */
}

.address {
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.272px; /* 200% */
  display: flex;
  gap: 6px;
}

.consignor-label {
  // width: 61px;
  flex: 1;
  height: 19px;
  // padding-top: 5.65px;
  padding-left: 8px;
}

.address-label {
  flex: 1;
  // width: 45px;
  height: 19px;
  padding-left: 8px;
}

.consignee-label {
  flex: 1;
  // width: 61px;
  height: 19px;
  // padding-top: 5.65px;
  padding-left: 8px;
}
.consignor-value {
  flex: 6;
}
.address-value {
  flex: 6;
}
.consignor-details-container {
  height: 100px;
  // border: 0.761px solid #373737;
}

.city-state {
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.272px; /* 200% */
  border-bottom: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
  display: flex;
}

.city {
  width: 181.447px;

  flex-shrink: 0;
  background: #fff;
  border-right: 0.761px solid #373737;
}

.city-label {
  padding-left: 8px;
  padding-top: 2.15px;
}

.state-label {
  padding-left: 8px;
  padding-top: 2.15px;
}

.state {
  width: 174.328px;

  flex-shrink: 0;
  // border-bottom: 0.761px solid #373737;
  // border-right: 0.761px solid #373737;
  background: #fff;
}

.city-consignee {
  width: 217.904px;

  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  background: #fff;
}

.state-consignee {
  width: 218.993px;

  flex-shrink: 0;
  // border-bottom: 0.761px solid #373737;
  // border-right: 0.761px solid #373737;
  background: #fff;
}

.parameters-container {
  color: #373737;
  text-align: center;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.272px; /* 200% */
  display: flex;
}

.parameters-1 {
  height: 110.568px;
  width: 355.514px;
  border-bottom: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
  border-left: 0.761px solid #373737;
}

.parameters-2 {
  height: 110.568px;
  width: 218.391px;
  border-bottom: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
  border-left: 0.761px solid #373737;
}

.parameters-3 {
  height: 110.568px;
  width: 219.824px;
  border-bottom: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
  border-left: 0.761px solid #373737;
  display: flex;
}

.packaging-container {
  display: flex;
}

.weight-container {
  display: flex;
}

.no-pkgs-label {
  width: 86.222px;
  height: 31.514px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-pkgs-value {
  width: 86.222px;
  height: 57.9px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  // border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pkg-type-value {
  width: 70.07px;
  height: 57.9px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  // border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pkg-type-label {
  width: 70.07px;
  height: 31.514px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disp-goods-value {
  width: 108.584px;
  height: 57.9px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  // border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disp-goods-label {
  width: 108.584px;
  height: 31.514px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.weight-value {
  width: 92.00125px;
  height: 57.9px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  // border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.weight-label {
  width: 92.00125px;
  height: 31.514px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.part-no {
  display: flex;
}
.part-no-label {
  width: 85.851px;
  height: 21.154px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  background: #fff;
  flex: 1;
}

.part-no-value {
  flex: 3.14;
}

.vol-weight-label {
  width: 108.852px;
  height: 31.514px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vol-weight-value {
  width: 108.852px;
  height: 57.9px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  // border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.charge-weight-label {
  width: 109.539px;
  height: 31.514px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.charge-weight-value {
  width: 109.539px;
  height: 57.9px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  // border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qty {
  display: flex;
  align-items: center;
}
.qty-label {
  flex: 1;
  width: 108.852px;
  height: 21.154px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qty-value {
  flex: 1;
}

.dim-label {
  width: 131.731px;
  height: 31.514px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dim-value {
  width: 131.562px;
  height: 78.054px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  // border-bottom: 0.761px solid #373737;
  // border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invoice-no-label {
  width: 88.262px;
  height: 31.514px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invoice-no-value {
  width: 88.262px;
  height: 78.054px;
  flex-shrink: 0;
  border-right: 0.761px solid #373737;
  // border-bottom: 0.761px solid #373737;
  // border-top: 0.761px solid #373737;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.para-1 {
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 794.5px;
  height: 31.739px;
  flex-shrink: 0;
  border-left: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
  border-bottom: 0.761px solid #373737;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4.87px;
  padding-bottom: 4.87px;
  padding-left: 8px;
  padding-right: 12px;
}

.bottom-container {
  display: flex;
}

.part-1-container {
  width: 261.774px;
  height: 79.545px;
  flex-shrink: 0;
  border-bottom: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
  border-left: 0.761px solid #373737;
  background: #fff;
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.received-goods {
  width: 198px;
  height: 22px;
  padding-left: 8px;
  padding-top: 5.93px;
}

.receiver-sign {
  padding-left: 8px;
  padding-top: 24.22px;
  width: 132px;
  height: 22px;
}

.part-2-container {
  width: 309.942px;
  height: 79.545px;
  flex-shrink: 0;
  border-bottom: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
  // border-left: 0.761px solid #373737;
  background: #fff;
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.received-goods-value {
  // width: 198px;
  height: 21.154px;
  padding-left: 8px;
  padding-top: 5.93px;
  border-bottom: 0.761px solid #373737;
}

.receiver-sign-value {
  padding-left: 8px;
  padding-top: 24.22px;
  // width: 132px;
  height: 22px;
}

.part-3-container {
  width: 223px;
  height: 79.545px;
  flex-shrink: 0;
  border-bottom: 0.761px solid #373737;
  border-right: 0.761px solid #373737;
  // border-left: 0.761px solid #373737;
  background: #fff;
  color: #373737;
  font-family: Roboto;
  font-size: 9.136px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.name-date-time {
  width: 198px;
  height: 22px;
  padding-left: 8px;
  padding-top: 5.93px;
}

.autho-sign {
  padding-left: 8px;
  padding-top: 24.22px;
  width: 132px;
  height: 22px;
}

.lr-form textarea {
  border-radius: 4px;
  border: 0.5px solid #aeaeae;
  background: #fff;
  display: flex;
  // padding: 0px 12px 6px 8px;
  padding: auto;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  color: #05284e;
}
