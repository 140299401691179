.file-upload-label:hover {
    background-color: #0056b3;
  }
  
  .file-upload-label:active {
    background-color: #003f7f;
  }
  
  .file-upload-wrapper {
    width: 100%;
    text-align: right;
  }

  .uploadButton {
    background-color: rgb(5, 5, 35);
    color: #fff;
    padding: 15px 20px;
    font-size: 15px;
    cursor: pointer;
    margin-left: 15px;
  }

  .CustomerModal,
.productModal,
.locationModal {
  .Submit {
    background-color: rgb(12, 12, 45);
    color: #fff;
    padding: 10px 30px;
    font-size: 20px;
  }
  .modal-content {
    padding: 40px;
  }
  .modal-title {
    width: 100%;
  }
  .Heading {
    display: flex;
    justify-content: space-between;
    // margin: 0px 20px;
    svg {
      cursor: pointer;
    }
  }
  .modal-body {
    display: flex;
    justify-content: center;
    .imageDiv {
      position: relative;
      width: 100%;
      height: 300px;
      background: #f5f5f5;
      border: 0.5px dashed #c6c6c6;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        border: 1px solid #decdcd;
        border-radius: 5px;
      }
      svg {
        width: 100%;
        cursor: pointer;
      }
      .clickText {
        display: flex;
        justify-content: center;
        font-size: 20px;
      }
    }
  }
}