.mainExpandedStyle {
  position: relative;
  border: 0.5px solid #d9d9d9;
  background: #fff;
  width: 225px;
  height: 1024px;
  flex-shrink: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  transition: 0.5s;
  overflow-x: hidden;
  padding-top: 60px;
  white-space: nowrap;
}

.expandIcon {
  margin: 8px;
  position: relative;
}

.mainLink,
.subMenuLinkDiv {
  position: relative;
  display: flex;
  padding: 6px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  margin-left: -5px;
  margin-top: 2px;
}

.mainExpandedStyle .mainLink:hover {
  transition: all 0.2s ease;
  background-color: #f0eeed;
  transform-origin: center;
  border-radius: 4px;
}

.mainExpandedStyle .mainLink:hover .mainLinkName {
  color: #000000 !important;
}

/* INDENT */
.mainExpandedStyle .activeSubLinkI,
.mainExpandedStyle .inActiveSubLinkI {
  transition: transform 0.3s;
  border: 1.5px solid transparent; /* Add a transparent border */
  border-radius: 4px;
  width: calc(100% - 8px);
}
.mainExpandedStyle .activeSubLinkI {
  background: #f0eeed;
}

.mainExpandedStyle .activeSubLinkI:hover,
.mainExpandedStyle .inActiveSubLinkI:hover {
  border: 1.5px solid transparent;
  background: #f0eeed;
  border-radius: 4px;
  color: black !important;
}

/* planning */
.activeLinkSectionIndent {
  border-radius: 4px;
  background: #f0eeed;
  margin: 8px 8px;
  color: #000000 !important;
}
/* Indent */

.activeSubLinkI {
  color: #000000 !important;
  background: #feecca !important;
  border: 1.5px solid transparent;
  border-radius: 4px;
  width: calc(100% - 8px);
}
******************************************

/* MASTER */

.mainExpandedStyle .activeSubLinkM,
.mainExpandedStyle .inActiveSubLinkM {
  transition: transform 0.3s;
  border: 1.5px solid transparent; /* Add a transparent border */
  border-radius: 4px;
  width: calc(100% - 8px);
}
.mainExpandedStyle .activeSubLinkM {
  color: #000000 !important;
  background: #cfeecf !important;
  border: 1.5px solid transparent;
  border-radius: 4px;
  width: calc(100% - 8px);
}

.mainExpandedStyle .activeSubLinkM:hover,
.mainExpandedStyle .inActiveSubLinkM:hover {
  border: 1.5px solid transparent;
  background: #f0eeed;
  border-radius: 4px;
  width: calc(100% - 8px);
  color: black !important;
}

************************************

/* TRIP */
.mainExpandedStyle .activeSubLinkT,
.mainExpandedStyle .inActiveSubLinkT {
  transition: transform 0.3s;
  border: 1.5px solid transparent; /* Add a transparent border */
  border-radius: 4px;
  width: calc(100% - 8px);
}
.mainExpandedStyle .activeSubLinkT {
  background: #e9e4ff;
}

.mainExpandedStyle .activeSubLinkT:hover,
.mainExpandedStyle .inActiveSubLinkT:hover {
  border: 1.5px solid transparent;
  border-radius: 4px;
  background: #f0eeed;
  width: calc(100% - 8px);
  color: black !important;
}

.activeSubLinkT {
  color: #000000 !important;
  background: #e9e4ff !important;
  border: 1.5px solid transparent;
  border-radius: 4px;
  width: calc(100% - 8px);
}
******************************************
/* TRIP */


/* INVOICE */
.mainExpandedStyle .activeSubLinkIV,
.mainExpandedStyle .inActiveSubLinkIV {
  transition: transform 0.3s;
  border: 1.5px solid transparent; /* Add a transparent border */
  border-radius: 4px;
  width: calc(100% - 8px);
}
.mainExpandedStyle .activeSubLinkIV {
  color: #000000 !important;
  background: #00adf71c !important;
  border: 1.5px solid transparent;
  border-radius: 4px;
  width: calc(100% - 8px);
}

/* .mainExpandedStyle .activeSubLinkIV:hover, */
.mainExpandedStyle .inActiveSubLinkIV:hover {
  border: 1.5px solid transparent;
  border-radius: 4px;
  background: #f0eeed;
  width: calc(100% - 8px);
  color: black !important;
}

******************************************
/* INVOICE */


/* REPORT */
.mainExpandedStyle .activeSubLinkR,
.mainExpandedStyle .inActiveSubLinkR {
  transition: transform 0.3s;
  border: 1.5px solid transparent; /* Add a transparent border */
  border-radius: 4px;
  width: calc(100% - -18px);
}
.mainExpandedStyle .activeSubLinkR {
  background: #f0eeed;
}

.mainExpandedStyle .activeSubLinkR:hover,
.mainExpandedStyle .inActiveSubLinkR:hover {
  border: 1.5px solid transparent;
  background: #f0eeed !important;
  border-radius: 4px;
  width: calc(100% - -18px);
  color: black !important;
}

.mainExpandedStyle .subMenuReport.subMenuLinkDivR:hover {
  transform: scale(1.025);
  border: 1.5px solid transparent;
  border-color: #e8e8e8;
  border-radius: 4px;
  padding-left: 8px;
  width: calc(190% - 8px);
}

.activeSubLinkR {
  color: #000000 !important;
  background: #2800fc1a !important;
  border: 1.5px solid transparent;
  border-radius: 4px;
  width: calc(100% - -18px);
}

.subMenuItemsReport {
  display: flex;
  flex-direction: column;
  margin-left: 11px;
  width: 74%;
  gap: 1px;
  position: relative;
}

.verticalLineR {
  margin-left: 26px; /* Adjust margin as needed */
  display: inline-block;
  height: 100%;
}

/* REPORT */

.activeMenuTextFocus {
  color: #000000 !important;
}

.subMenu {
  position: relative;
}
.inActiveSubLinkM {
  color: #6e6e6e !important;
}

.mainExpandedStyles {
  position: relative;
  border: 0.5px solid #d9d9d9;
  background: #fff;
  width: 225px;
  height: 1024px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  max-width: 82px;
  transition: 0.5s;
  overflow-x: hidden;
  padding-top: 60px;
  white-space: nowrap;
}

.mainLogoStyle {
  position: absolute;
  height: 86px;
  width: 80%;
  margin: auto;
  top: 22px;
  left: 10px;
}
.mainLogoStyleMobile {
  position: absolute;
  width: 80%;
  margin: auto;
  left: 0%;
  object-fit: fill;
}

.mainLogoStyleMini {
  position: absolute;
  height: 80px;
  width: 91%;
  margin: auto;
  top: 31px;
  left: 6px;
}

.logoSepartorStyle {
  position: absolute;
  border: 0.3px solid #d9d9d9;
  margin: auto;
  width: 86%;
  top: 162px;
  left: 7px;
}
.logoSepartorStyleMobile {
  position: absolute;
  border: 0.3px solid #d9d9d9;
  margin: auto;
  width: 92%;
  top: 162px;
  left: 4%;
}
.closeIconStyles {
  position: absolute;
  right: -1%;
  top: -1%;
  font-size: 40px;
  width: 30px;
}
.linksSectionMobile {
  position: absolute;
  top: 17%;
  width: 90%;
  margin: 8%;
  margin-top: 10%;
  cursor: pointer;
}

.linksSection {
  position: absolute;
  top: 170px;
  width: 100%;
  margin: 0.5%;
  margin-top: 2%;
  cursor: pointer;
}
.linksSectionMini {
  position: absolute;
  top: 196px;
  width: 100%;
  margin: 8.5%;
  margin-top: 2%;
  cursor: pointer;
}

.linkSection {
  width: 100%;
  margin-top: 0px;
}

.mainLink {
  padding: 0px 0px 0px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 8px;
}
.mainLink a {
  color: #6e6e6e;
}

.mainLinkMini {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  padding: 0;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6e6e6e;
}

.mainLinkName {
  position: absolute;
  margin-top: 18px;
  margin-left: 1%;
  font-family: Nunito;
  font-size: 14.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 129.257%; /* 18.096px */
  cursor: pointer;
  letter-spacing: 0.5px !important;
}
/* dashboard */
.activeLinkSection {
  border-radius: 4px;
  background: #f0eeed;
  margin: 8px 8px;
  color: #000000 !important;
}

.activeMenuTextFocus {
  color: #000000 !important;
}

.inActiveSubLinkM {
  color: #6e6e6e !important;
}

.subMenuLinkName {
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 129.257%; /* 18.096px */
  letter-spacing: 0.5px;
  margin-top: 2px;
  white-space: nowrap;
}

.hideSubMenu {
  display: none;
}

.displaySubMenu {
  display: block;
}

.subMenuSec {
  display: flex;
  align-items: center;
}

.verticalLine {
  margin-left: 31px;
  display: inline-block;
  height: 100%;
}

.subMenuItems {
  display: flex;
  flex-direction: column;
  margin-left: 26px;
  width: 74%;
  gap: 1px;
  position: relative;
}

.menuIcon {
  display: inline-flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
}

/* Define style for active submenu MASTER link */
.subMenu a {
  color: #6e6e6e !important;
}
.toggle-switch-1 {
  /* margin-top: 28px; */
  display: flex;
  max-width: 220px;
  align-items: center;
  justify-content: center;
  border-radius: 46px;
  background-color: #1b1c31;
  position: absolute;
  top: -17px;
  cursor: pointer;
  /* right: 980px; */
}

.toggle-switch-1 div.isActivated {
  background: #0ab38c;
  border-radius: 26px;
  cursor: pointer;
}

.toggle-switch-1 div {
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  padding: 4px 14px;
}

@media screen and (max-height: 450px) {
  #sidebar {
    padding-top: 15px;
  }
  #sidebar a {
    font-size: 18px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 600px) {
  .dot-display {
    right: 216px !important;
  }
}

/* .helpMenuStyle*/

.mainLinkHelp {
  display: flex;
  align-items: center; /* Align items vertically */
  height: 57px;
  padding: 7%;
  text-align: center;
}

.helpIcon {
  height: auto;
  margin-right: 18px;
}

.helpMenuStyle {
  position: absolute;
  top: 101%;
  width: 91%;
  margin: 4%;
  margin-top: 2%;
  cursor: pointer;
  border-top: 1px solid transparent;
  border-color: #e8e8e8;
  border-radius: 4px;
  overflow: hidden;
}

.helpName {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  color: #6e6e6e;
  cursor: pointer;
}

.toggle-switch-1 {
  /* margin-top: 28px; */
  display: flex;
  max-width: 220px;
  align-items: center;
  justify-content: center;
  border-radius: 46px;
  background-color: #1b1c31;
  /* position: absolute; */
  /* top: -17px; */
  cursor: pointer;
}

.toggle-switch-1 div.isActivated {
  background: #0ab38c;
  border-radius: 26px;
  cursor: pointer;
}

.toggle-switch-1 div {
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  padding: 4px 14px;
}

.dropdownField {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background-color: white;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
}
.dropdown-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: black;
  padding: 12px;
  z-index: 999;
}
.options-container {
  border-radius: 4px;
  position: absolute;
  bottom: -88px;
  background-color: white;
  width: 100%;
}
.options-item {
  width: 100%;
  cursor: pointer;
  padding: 8px;
}
.options-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.options-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.options-item.active {
  background-color: #2800fc;
  color: white;
}
